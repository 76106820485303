<template>
  <client-only>
    <layout-modal
      :showModal="showModal"
      modalClass="modal-confirm-bonus-unsettled"
      @close-modal="emit('closeModal')"
    >

      <modal-content-confirm-bonus-unsettled v-bind="props" />

      <template v-slot:footer>
        <div class="actions">
          <button-base
            type="primary"
            size="md"
            @click="emit('closeModal')"
          >
            {{ props.cancelButton }}
          </button-base>

          <button-base type="gray" size="md" @click="emit('confirm')" :isDisabled="bonusesUpdating">
            <atomic-spinner :is-shown="props.bonusesUpdating" />
            {{ props.confirmButton }}
          </button-base>
        </div>
      </template>
    </layout-modal>
  </client-only>
</template>

<script setup lang="ts">
  import type { IPlayerBonus } from '~/skeleton/core/types';

  const props = defineProps<{
    showModal: boolean,
    title?: string,
    image?: string,
    description?: string,
    confirmButton?: string,
    cancelButton?: string,
    bonusesUpdating?: boolean
    bonusInfo?: IPlayerBonus,
    wageringLabel?: string
  }>();

  const emit = defineEmits(['closeModal', 'confirm']);
</script>

<style src="~/assets/styles/components/modal/confirm-bonus-unsettled.scss" lang="scss" />

